@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.notifications_box {
  position: fixed;
  top: 64px;
  right: 30px;
  min-width: 400px;
  width: 28vw;
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
}

.notif_line_wrapper {
  position: relative;
  width: 90%;
  left: 5%;
  height: auto;
}

.notif_line_wrapper .notif_line {
  position: relative;
  height: auto;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  line-height: 1.1rem;
  margin-bottom: 15px;
  border-radius: 6px;
  padding: 12px 20px;
}

.notif_line_wrapper .notif_line.unread {
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  border-radius: 6px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.notif_line_wrapper .notif_line.unread::before {
  position: absolute;
  display: block;
  width: 4px;
  height: 100%;
  top: 0px;
  left: 0px;
  background: -webkit-gradient(linear, left top, left bottom, from(#4FEDED), to(#009DFF));
  background: linear-gradient(to bottom, #4FEDED, #009DFF);
  content: '';
  z-index: 1;
  border-radius: 6px 0px 0px 6px;
}

.notif_line_wrapper .notif_line.unread.alert {
  -webkit-box-shadow: 0 6px 20px rgba(231, 76, 60, 0.17);
          box-shadow: 0 6px 20px rgba(231, 76, 60, 0.17);
}

.notif_line_wrapper .notif_line.unread.alert p {
  color: #e74c3c;
}

.notif_line_wrapper .notif_line p {
  font-size: .9rem;
  color: #2F3E68;
  font-weight: 300;
  margin-bottom: 0px;
  text-align: left;
}

.notif_line_wrapper .empty_label {
  color: rgba(47, 62, 104, 0.4);
  text-align: center;
}

.notif_filter {
  position: fixed;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
}
