@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.interest_calc {
  height: auto !important;
}

.interest_calc .interest_calc_grid {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 2fr;
      grid-template-columns: 1fr 1fr 2fr;
  height: auto;
  max-width: 90%;
  grid-column-gap: 30px;
  margin-top: -20px;
}

.interest_calc .interest_calc_grid .grid_content_box {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 50px;
  padding: 10px 20px;
}

.interest_calc .interest_calc_grid .grid_content_box .stat_h3 {
  position: relative;
  font-size: 1.7rem;
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
  color: #2F3E68;
}

.interest_calc .interest_calc_grid .grid_content_box .legend {
  position: relative;
  font-size: .9rem;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(47, 62, 104, 0.65);
}

.interest_calc .buttons_grid {
  position: absolute;
  width: 55%;
  display: -ms-grid;
  display: grid;
  float: right;
  bottom: 0px;
  right: 20px;
  -ms-grid-columns: 1fr 1.3fr;
      grid-template-columns: 1fr 1.3fr;
  grid-column-gap: 20px;
}

.interest_calc .cr_prg_interest_calc {
  width: 100%;
}

.interest_calc .cr_prg_interest_calc .legend {
  position: relative;
  display: inline-block;
  left: 10px;
  top: -30px;
  color: #2F3E68;
}

.interest_calc .cr_prg_interest_calc .legend .light {
  font-weight: 300;
  font-size: .9rem;
  margin-top: 4px;
}
