@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.network_component {
  position: absolute;
  width: 100%;
  padding-top: 100px;
  left: 0%;
}

.network_component .fullpage_component_headline {
  position: relative;
  top: -90px;
  right: 60px;
}

.network_component .cl_header_grid {
  position: relative;
  width: 80%;
  left: 0%;
  top: -60px;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
  height: auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
}

.network_component .cl_header_grid .grid_item {
  position: relative;
}

.network_component .cl_header_grid .grid_item p {
  font-size: .95rem;
  font-family: Roboto, sans-serif;
  color: #2F3E68;
  font-weight: 400;
  margin-bottom: 10px;
}

.network_component .cl_header_grid .grid_item p.legend {
  color: rgba(47, 62, 104, 0.65);
  font-weight: 600;
  font-size: .8rem;
}

.network_component .cl_header_grid .grid_item p.amount {
  font-family: 'Roboto Mono';
  font-weight: 600;
  letter-spacing: 0px;
}

.network_component .cl_header_grid .reg_button {
  max-width: 100%;
  width: 70%;
  left: -40%;
  position: relative;
}

.network_component .cl_header_grid .reg_button_link {
  max-width: 100%;
  width: 70%;
  left: -43%;
  top: -10px;
  position: relative;
  margin-top: -20px;
  margin-bottom: -70px;
}

.network_component .main_content_box {
  position: relative;
  width: 100%;
  height: auto;
  top: -120px;
  padding-bottom: 80px;
}

.network_component .main_content_box.top_padding {
  top: -20px;
}
