@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.custom-tooltip {
  position: relative;
  height: auto;
  background: rgba(250, 250, 250, 0.8);
  border: 1px solid rgba(136, 132, 216, 0.8);
  padding: 10px;
  top: 100px;
}

.custom-tooltip p {
  padding: 0px;
  margin-bottom: 6px;
}

.custom-tooltip p.label {
  font-size: .8rem;
  color: rgba(47, 62, 104, 0.5);
}

.custom-tooltip p.val {
  font-size: 1rem;
  color: rgba(47, 62, 104, 0.8);
}
