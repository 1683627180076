@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.user_investment_overview_wrapper {
  min-height: 350px;
  max-height: 22em;
}

.user_investment_overview_wrapper .container_box_label {
  position: absolute;
  top: 15px;
  right: 20px;
}

.user_investment_overview_wrapper .inv_overview_container {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  width: 100%;
  height: 100%;
}

.user_investment_overview_wrapper .investment_table_wrapper {
  position: relative;
  width: 100%;
  border: 1px solid  #ccc;
  height: 22em;
  margin-top: 0px;
  max-height: 22em;
}

.user_investment_overview_wrapper .investment_table_wrapper .itw_line {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: 96%;
  left: 2%;
  border: 1px solid  #ccc;
  -ms-grid-columns: .6fr .8fr .5fr .9fr .9fr .5fr;
      grid-template-columns: .6fr .8fr .5fr .9fr .9fr .5fr;
  height: 25px;
  line-height: 25px;
  vertical-align: center;
}

.user_investment_overview_wrapper .investment_table_wrapper .itw_line p {
  font-family: Roboto;
  font-weight: 300;
  font-size: .7rem;
  color: #2F3E68;
}

.user_investment_overview_wrapper .investment_table_wrapper .itw_line p.currency {
  font-size: .7rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
}

.user_investment_overview_wrapper .investment_table_wrapper .itw_line p.move_up {
  color: #25C873;
}

.user_investment_overview_wrapper .investment_table_wrapper .itw_line p.move_down {
  color: #FF7791;
}

.user_investment_overview_wrapper .investment_table_wrapper .line_legend {
  border-bottom: 1px solid #2F3E68;
  height: 25px;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.user_investment_overview_wrapper .investment_table_wrapper .line_legend p {
  font-family: 'Roboto Mono';
  font-weight: 600;
  font-size: .8rem;
  color: rgba(47, 62, 104, 0.65);
}

.user_investment_overview_wrapper .investment_table_wrapper .inv_line_box_wrapper {
  position: relative;
  padding-top: 14px;
  margin-top: 0px;
  padding-bottom: 14px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
  width: 100%;
  height: calc( 32px + 14px + 4*46px);
  grid-row-gap: 16px;
  overflow: auto;
  height: 19.5em;
}

.user_investment_overview_wrapper .investment_table_wrapper .inv_line_box_wrapper .inv_line_box {
  position: relative;
  left: 2%;
  width: 96%;
  height: 46px;
  -webkit-box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
          box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
  border-radius: 6px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: .6fr .8fr .5fr .9fr .9fr .5fr;
      grid-template-columns: .6fr .8fr .5fr .9fr .9fr .5fr;
  padding: 2px 15px;
}

.user_investment_overview_wrapper .investment_table_wrapper .inv_line_box_wrapper .inv_line_box p {
  font-family: Roboto;
  font-weight: 300;
  font-size: .7rem;
  margin-bottom: 0px !important;
  color: #2F3E68;
}

.user_investment_overview_wrapper .investment_table_wrapper .inv_line_box_wrapper .inv_line_box p.currency {
  font-size: .7rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
}

.user_investment_overview_wrapper .investment_table_wrapper .inv_line_box_wrapper .inv_line_box .inv_overview_circ {
  height: 42px !important;
}

.user_investment_overview_wrapper .investment_table_wrapper .pmt_context_line {
  position: relative;
  margin-top: 4px;
  left: 2%;
  margin-bottom: 0px !important;
  font-size: .8rem;
  font-weight: 300;
  width: 200px;
}

.user_investment_overview_wrapper .investment_table_wrapper .pmt_context_line_no_inv {
  position: relative;
  margin-top: 14px;
  margin-bottom: 0px !important;
  font-size: .8rem;
  font-weight: 300;
  width: auto;
  text-align: center;
}

.center_new_inv_box {
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 50%;
  height: 50%;
}

.center_new_inv_box .content_box {
  height: 100%;
}

.center_new_inv_box .new_investment_btn {
  position: absolute;
  bottom: -20px;
  right: 10px;
}

@media only screen and (max-width: 1400px) {
  .investment_overview_wrapper {
    min-height: 45vh;
  }
  .investment_overview_wrapper .investment_table_wrapper .itw_line p {
    font-size: .7rem;
  }
  .investment_overview_wrapper .investment_table_wrapper .itw_line p.currency {
    font-size: .7rem;
  }
  .investment_overview_wrapper .investment_table_wrapper .line_legend {
    -ms-grid-columns: 1.4fr .9fr .4fr .9fr .9fr .5fr;
        grid-template-columns: 1.4fr .9fr .4fr .9fr .9fr .5fr;
  }
  .investment_overview_wrapper .investment_table_wrapper .line_legend p {
    font-size: .7rem;
  }
  .investment_overview_wrapper .investment_table_wrapper .inv_line_box_wrapper .inv_line_box {
    -ms-grid-columns: 1.4fr .9fr .4fr .9fr .9fr .5fr;
        grid-template-columns: 1.4fr .9fr .4fr .9fr .9fr .5fr;
  }
  .investment_overview_wrapper .investment_table_wrapper .inv_line_box_wrapper .inv_line_box p {
    font-size: .7rem;
    letter-spacing: 0px;
  }
  .investment_overview_wrapper .investment_table_wrapper .inv_line_box_wrapper .inv_line_box p.currency {
    font-size: .7rem;
    letter-spacing: 0px;
  }
}
