@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.settings_component .user_status {
  font-size: 1rem;
  font-weight: 300;
}

.settings_component .user_status .user_status_inner {
  font-weight: 300;
  color: rgba(47, 62, 104, 0.65);
}

.settings_component .user_status .user_status_inner.active_user {
  color: #25C873;
}

.settings_component .user_status .user_status_inner.error {
  color: #FF7791;
}

.settings_component .settings_grid {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-auto-flow: row dense;
  margin-top: 20px;
}

.settings_component .settings_grid .content_box {
  position: relative;
  width: 100%;
}

.settings_component .settings_grid .grid_box {
  height: auto;
}

.settings_component .text_edit_item.mid_size_input {
  position: relative;
  width: 75%;
  max-width: 75%;
}

.settings_component .text_edit_item.hlaf_input {
  position: relative;
  width: 47%;
  max-width: 47%;
  display: inline-block;
  margin-left: 2%;
}

.settings_component .input_container_wrapper {
  position: relative;
  width: 95%;
  left: 2.5%;
}

.settings_component .in_box_switch {
  width: 60px;
}

.settings_component .outer_switch {
  width: 48px;
}

.settings_component .padding_line {
  position: relative;
  height: 20px;
}

.settings_component .cb_label_left_profile_pic {
  margin-left: 20px;
  font-size: .8rem;
  margin-bottom: 10px;
}

.settings_component .user_profile_pic {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 8fr;
      grid-template-columns: 1fr 8fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 30px;
  margin-left: 20px;
}

.settings_component .user_profile_pic .profioe_pic_upload {
  position: relative;
  top: 10px;
}

.settings_component .user_profile_pic .profile_img {
  position: relative;
  width: 60px;
  height: 60px;
  max-height: 60px;
  max-width: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  border-radius: 60px;
}

.settings_component .user_profile_pic .empty_img {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  background-color: #FFFFFF;
  background-image: url("../../img/profile_pic.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.settings_component .action_context_box_wrpaper {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3fr 1fr;
      grid-template-columns: 3fr 1fr;
  margin-top: 10px;
  margin-bottom: -25px;
}

.settings_component .action_context_box_wrpaper .label {
  margin-top: 5px;
  margin-left: 10px;
}

.settings_component .small_contextbox {
  margin-bottom: 30px;
}

.settings_component .user_info {
  position: relative;
  left: 30px;
  top: 10px;
  height: 40px;
  width: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
}

.settings_component .user_info .profile_img {
  position: relative;
  width: 45px;
  height: 45px;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  border-radius: 100px;
  max-height: 45px;
  max-width: 45px;
  -o-object-fit: cover;
     object-fit: cover;
}

.settings_component .user_info .empty_img {
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  background-color: #FFFFFF;
  background-image: url("../../img/profile_pic.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.settings_component .user_info p {
  font-family: Raleway, sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  position: relative;
  top: 10px;
  left: 15px;
}

.settings_component .contact_btn {
  position: absolute;
  right: 10px;
  top: calc(100% - 30px);
}

.settings_component .action_box_wrpaper {
  width: 100%;
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 4fr 1fr;
      grid-template-columns: 4fr 1fr;
  top: 20px;
}

.settings_component .action_box_wrpaper .update_btn {
  position: relative;
  top: -8px;
}

.settings_component .list_wrapper {
  height: auto;
  position: relative;
  width: 100%;
  border: 1px solid  #ccc;
}

.settings_component .list_wrapper .acm_line {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: 96%;
  left: 2%;
  border: 1px solid  #ccc;
  -ms-grid-columns: 1fr 3fr 1fr;
      grid-template-columns: 1fr 3fr 1fr;
  height: 25px;
  line-height: 25px;
  vertical-align: center;
}

.settings_component .list_wrapper .acm_line p {
  font-family: Roboto;
  font-weight: 300;
  font-size: .8rem;
  color: #2F3E68;
}

.settings_component .list_wrapper .acm_line p.currency {
  font-size: .8rem;
  color: #2F3E68;
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
}

.settings_component .list_wrapper .line_legend {
  border-bottom: 1px solid #2F3E68;
  height: 25px;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.settings_component .list_wrapper .line_legend p {
  font-family: 'Roboto Mono';
  font-weight: 600;
  font-size: .7rem;
  letter-spacing: 1px;
  color: #2F3E68;
}

.settings_component .list_wrapper .acm_line_box_wrapper {
  position: relative;
  padding-top: 14px;
  padding-bottom: 14px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  width: 100%;
  height: auto;
  grid-row-gap: 16px;
}

.settings_component .list_wrapper .acm_line_box_wrapper .acm_line_box {
  position: relative;
  left: 2%;
  width: 96%;
  height: 46px;
  -webkit-box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
          box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
  border-radius: 6px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: 1fr 3fr 1fr;
      grid-template-columns: 1fr 3fr 1fr;
  padding: 2px 15px;
  cursor: pointer;
  -webkit-transition: -webkit-box-shadow .4s, -webkit-transform .4s;
  transition: -webkit-box-shadow .4s, -webkit-transform .4s;
  transition: box-shadow .4s, transform .4s;
  transition: box-shadow .4s, transform .4s, -webkit-box-shadow .4s, -webkit-transform .4s;
}

.settings_component .list_wrapper .acm_line_box_wrapper .acm_line_box:hover {
  -webkit-box-shadow: 0 6px 30px rgba(0, 186, 255, 0.3);
          box-shadow: 0 6px 30px rgba(0, 186, 255, 0.3);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.settings_component .list_wrapper .acm_line_box_wrapper .acm_line_box p {
  font-family: Roboto;
  font-weight: 300;
  font-size: .8rem;
  margin-bottom: 0px !important;
  color: #2F3E68;
}

.settings_component .list_wrapper .acm_line_box_wrapper .acm_line_box p.currency {
  font-size: .8rem;
  color: #2F3E68;
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
}

.settings_component .list_wrapper .acm_line_box_wrapper .acm_line_box .inv_overview_circ {
  height: 42px !important;
}
