@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
#confirm {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 999;
  background: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  -webkit-transform: all .6s;
          transform: all .6s;
}

#confirm.page {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

#confirm .confrim_box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-top: 4px;
  margin-left: 4px;
  height: 200px;
  width: 450px;
}

#confirm .confirm_wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
  height: 70%;
  top: 15%;
}

#confirm .confirm_wrapper .center_buttons_grid {
  position: absolute;
  width: 70%;
  left: 15%;
  bottom: 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

#confirm .confirm_wrapper .confirm_text {
  position: relative;
  top: 30px;
}
