@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.clients_component .search_input {
  position: absolute;
  right: 40px;
  top: 90px;
  width: 250px;
}

.clients_component .search_input .input {
  width: 100%;
}

.clients_component .search_input .input input {
  font-size: .8rem;
}

.clients_component .cl_header_grid {
  position: relative;
  width: 80%;
  left: 3%;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
  height: auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
}

.clients_component .cl_header_grid .grid_item {
  position: relative;
}

.clients_component .cl_header_grid .grid_item p {
  font-size: .95rem;
  font-family: Roboto, sans-serif;
  color: #2F3E68;
  font-weight: 400;
  margin-bottom: 10px;
}

.clients_component .cl_header_grid .grid_item p.legend {
  color: rgba(47, 62, 104, 0.65);
  font-weight: 600;
  font-size: .8rem;
}

.clients_component .cl_header_grid .grid_item p.amount {
  font-family: 'Roboto Mono';
  font-weight: 600;
  letter-spacing: 0px;
}

.clients_component .cl_header_grid .reg_button {
  max-width: 100%;
  width: 70%;
  left: -40%;
  position: relative;
}

.clients_component .main_content_box .cl_line {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: 96%;
  left: 2%;
  border: 1px solid  #ccc;
  -ms-grid-columns: 1.5fr 1fr 1fr 1fr .4fr;
      grid-template-columns: 1.5fr 1fr 1fr 1fr .4fr;
  height: 25px;
  line-height: 25px;
  vertical-align: center;
}

.clients_component .main_content_box .cl_line p {
  font-family: Roboto;
  font-weight: 300;
  font-size: .8rem;
  color: #2F3E68;
}

.clients_component .main_content_box .cl_line p.currency {
  font-size: .8rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
}

.clients_component .main_content_box .line_legend {
  border-bottom: 1px solid rgba(47, 62, 104, 0.65);
  height: 25px;
  margin-bottom: 5px;
  padding-left: 35px;
  padding-right: 35px;
}

.clients_component .main_content_box .line_legend p {
  font-family: 'Roboto Mono';
  font-weight: 600;
  font-size: .85rem;
  color: rgba(47, 62, 104, 0.65);
}

.clients_component .main_content_box .empty_label {
  color: rgba(47, 62, 104, 0.4);
  text-align: center;
  position: relative;
  margin-top: 40px;
}

.clients_component .main_content_box .cl_line_box_wrapper {
  position: relative;
  padding-top: 14px;
  padding-bottom: 14px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  width: 100%;
  height: auto;
  grid-row-gap: 16px;
}

.clients_component .main_content_box .cl_line_box_wrapper .cl_line_box {
  position: relative;
  left: 2%;
  width: 96%;
  height: 52px;
  -webkit-box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
          box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
  background-color: #FFF;
  border-radius: 6px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: .3fr 1.2fr 1fr 1fr .6fr .4fr .4fr;
      grid-template-columns: .3fr 1.2fr 1fr 1fr .6fr .4fr .4fr;
  padding: 2px 35px;
  -webkit-transition: all .4s;
  transition: all .4s;
  cursor: pointer;
}

.clients_component .main_content_box .cl_line_box_wrapper .cl_line_box:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  -webkit-box-shadow: 0 6px 30px rgba(0, 186, 255, 0.3);
          box-shadow: 0 6px 30px rgba(0, 186, 255, 0.3);
}

.clients_component .main_content_box .cl_line_box_wrapper .cl_line_box.active {
  border-left: 5px solid #25C873;
}

.clients_component .main_content_box .cl_line_box_wrapper .cl_line_box.tpye1 {
  border-left: 5px solid #27DEFF;
}

.clients_component .main_content_box .cl_line_box_wrapper .cl_line_box.tpye2 {
  border-left: 5px solid #FF7791;
}

.clients_component .main_content_box .cl_line_box_wrapper .cl_line_box .profile_img {
  position: relative;
  width: 38px;
  height: 38px;
  max-height: 38px;
  max-width: 38px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  border-radius: 100px;
  margin-left: -15px;
}

.clients_component .main_content_box .cl_line_box_wrapper .cl_line_box p {
  font-family: Roboto;
  font-weight: 400;
  font-size: .8rem;
  margin-bottom: 0px !important;
  color: #2F3E68;
}

.clients_component .main_content_box .cl_line_box_wrapper .cl_line_box p.green_cl {
  color: #25C873;
}

.clients_component .main_content_box .cl_line_box_wrapper .cl_line_box p.red_cl {
  color: #FF7791;
}

.clients_component .main_content_box .cl_line_box_wrapper .cl_line_box p.currency {
  font-size: .85rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
}
