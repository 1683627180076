@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
#manual_account_manager {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 999;
  background: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  -webkit-transform: all .6s;
          transform: all .6s;
}

#manual_account_manager.page {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

#manual_account_manager .manual_account_manager {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 80vh;
}

#manual_account_manager .edit_account_btn {
  float: right;
  min-width: 45%;
  margin-bottom: 10px;
}

#manual_account_manager .account_edit_item {
  margin-top: 5px;
}

#manual_account_manager .account_edit_item .input {
  width: 100%;
  max-width: 100%;
}

#manual_account_manager .close_bnt {
  position: fixed;
  top: 40px;
  right: 40px;
  height: 30px;
  width: 30px;
  background-image: url("../../img/icons/close_btn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 110;
  cursor: pointer;
}

#manual_account_manager .ac_manager_grid {
  position: relative;
  width: 100%;
  height: 92%;
  display: -ms-grid;
  display: grid;
  grid-column-gap: 20px;
  -ms-grid-columns: 2fr 1fr;
      grid-template-columns: 2fr 1fr;
}

#manual_account_manager .ac_manager_grid .ac_grid_accounts {
  height: 100%;
  max-height: 70vh;
  overflow-y: auto;
}

#manual_account_manager .ac_manager_grid .ac_grid_accounts .list_wrapper {
  height: auto;
  position: relative;
  width: 100%;
  border: 1px solid  #ccc;
}

#manual_account_manager .ac_manager_grid .ac_grid_accounts .list_wrapper .acm_line {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: 96%;
  left: 2%;
  border: 1px solid  #ccc;
  -ms-grid-columns: .8fr 1.2fr 1fr 1fr .4fr;
      grid-template-columns: .8fr 1.2fr 1fr 1fr .4fr;
  height: 25px;
  line-height: 25px;
  vertical-align: center;
}

#manual_account_manager .ac_manager_grid .ac_grid_accounts .list_wrapper .acm_line p {
  font-family: Roboto;
  font-weight: 300;
  font-size: .8rem;
  color: #2F3E68;
}

#manual_account_manager .ac_manager_grid .ac_grid_accounts .list_wrapper .acm_line p.currency {
  font-size: .8rem;
  color: #2F3E68;
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
}

#manual_account_manager .ac_manager_grid .ac_grid_accounts .list_wrapper .line_legend {
  border-bottom: 1px solid #2F3E68;
  height: 25px;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

#manual_account_manager .ac_manager_grid .ac_grid_accounts .list_wrapper .line_legend p {
  font-family: 'Roboto Mono';
  font-weight: 600;
  font-size: .7rem;
  letter-spacing: 1px;
  color: #2F3E68;
}

#manual_account_manager .ac_manager_grid .ac_grid_accounts .list_wrapper .acm_line_box_wrapper {
  position: relative;
  padding-top: 14px;
  padding-bottom: 14px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  width: 100%;
  height: auto;
  grid-row-gap: 16px;
}

#manual_account_manager .ac_manager_grid .ac_grid_accounts .list_wrapper .acm_line_box_wrapper .acm_line_box {
  position: relative;
  left: 2%;
  width: 96%;
  height: 46px;
  -webkit-box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
          box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
  border-radius: 6px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: .8fr 1.2fr 1fr 1fr .4fr;
      grid-template-columns: .8fr 1.2fr 1fr 1fr .4fr;
  padding: 2px 15px;
  cursor: pointer;
  -webkit-transition: -webkit-box-shadow .4s, -webkit-transform .4s;
  transition: -webkit-box-shadow .4s, -webkit-transform .4s;
  transition: box-shadow .4s, transform .4s;
  transition: box-shadow .4s, transform .4s, -webkit-box-shadow .4s, -webkit-transform .4s;
}

#manual_account_manager .ac_manager_grid .ac_grid_accounts .list_wrapper .acm_line_box_wrapper .acm_line_box:hover {
  -webkit-box-shadow: 0 6px 30px rgba(0, 186, 255, 0.3);
          box-shadow: 0 6px 30px rgba(0, 186, 255, 0.3);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

#manual_account_manager .ac_manager_grid .ac_grid_accounts .list_wrapper .acm_line_box_wrapper .acm_line_box p {
  font-family: Roboto;
  font-weight: 300;
  font-size: .8rem;
  margin-bottom: 0px !important;
  color: #2F3E68;
}

#manual_account_manager .ac_manager_grid .ac_grid_accounts .list_wrapper .acm_line_box_wrapper .acm_line_box p.currency {
  font-size: .8rem;
  color: #2F3E68;
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
}

#manual_account_manager .ac_manager_grid .ac_grid_accounts .list_wrapper .acm_line_box_wrapper .acm_line_box .inv_overview_circ {
  height: 42px !important;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1.2fr 1fr 1fr;
      grid-template-rows: 1.2fr 1fr 1fr;
  height: 70%;
  grid-row-gap: 20px;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_edit {
  height: 100%;
  width: 100%;
  padding-left: 40px;
  min-height: 0%;
  max-height: 100%;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_detail {
  height: 100%;
  width: 100%;
  padding-left: 40px;
  min-height: 0%;
  max-height: 100%;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_detail .user_info {
  position: relative;
  left: 20px;
  top: 20px;
  height: 40px;
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_detail .user_info .profile_img {
  position: relative;
  width: 50px;
  height: 50px;
  max-height: 50px;
  max-width: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  border-radius: 100px;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_detail .user_info p {
  font-family: Raleway, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  position: relative;
  top: 12px;
  color: #2F3E68;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_detail .account_balance {
  position: relative;
  font-family: 'Roboto Mono', monospace;
  font-size: 2rem;
  color: #2F3E68;
  margin-bottom: 15px;
  letter-spacing: 2px;
  font-weight: 600;
  margin-top: 40px;
  margin-left: 20px;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_detail .po_grid {
  position: relative;
  width: 95%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  height: auto;
  margin-bottom: 0px;
  margin-left: 20px;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_detail .po_grid .prev_chart {
  position: relative;
  height: 100%;
  width: 95%;
  left: 5%;
  top: -20px;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_detail .po_grid .circular_progressbar_box {
  position: relative;
  width: auto;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_detail .po_grid .circular_progressbar_box .circular_progressbar {
  width: 80px;
  height: 80px;
  font-family: Roboto;
  font-weight: 200;
  display: inline-block;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_detail .po_grid .circular_progressbar_box .textbox {
  position: relative;
  display: inline-block;
  height: auto;
  left: 10px;
  top: -10px;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_detail .po_grid .circular_progressbar_box .textbox p {
  line-height: 1.2rem;
  font-size: .8rem;
  position: relative;
  margin: 0px;
  padding: 0px;
  color: #2F3E68;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_detail .po_grid .circular_progressbar_box .textbox .amount {
  font-family: 'Roboto Mono', monospace;
  font-weight: 600;
  line-height: 1.2rem;
  font-size: 1rem;
}

#manual_account_manager .ac_manager_grid .ac_grid_actions_wrapper .acm_account_detail .po_grid .circular_progressbar_box .textbox .amount_label {
  font-family: Roboto;
  font-weight: 300;
}
