@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.text_edit_component .main_content_box {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
}

.text_edit_component .main_content_box .empty_label {
  color: rgba(47, 62, 104, 0.4);
  text-align: center;
  position: relative;
  margin-top: 40px;
}

.text_edit_component .main_content_box .full_input {
  width: 100%;
}

.text_edit_component .main_content_box .full_input .input {
  width: 100%;
}
