@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
#log_detail {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 999;
  background: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  -webkit-transform: all .6s;
          transform: all .6s;
}

#log_detail.page {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

#log_detail .log_box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 55vh;
}

#log_detail .edit_account_btn {
  float: right;
  min-width: 45%;
  margin-bottom: 10px;
}

#log_detail .account_edit_item {
  margin-top: 5px;
}

#log_detail .account_edit_item .input {
  width: 100%;
  max-width: 100%;
}

#log_detail .cb_label_right_large {
  position: relative;
  right: 10px;
  float: right;
  top: 10px;
  text-align: right;
  font-size: 1.7rem;
  font-family: Raleway, sans-serif;
  font-weight: 600;
}

#log_detail .close_bnt {
  position: fixed;
  top: 40px;
  right: 40px;
  height: 30px;
  width: 30px;
  background-image: url("../../img/icons/close_btn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 110;
  cursor: pointer;
}

#log_detail .user_info_page {
  position: relative;
  left: 10%;
  top: 0px;
  height: 40px;
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#log_detail .user_info_page .profile_img {
  position: relative;
  width: 60px;
  height: 60px;
  max-height: 60px;
  max-width: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  border-radius: 100px;
}

#log_detail .user_info_page p {
  font-family: Raleway, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  position: relative;
  top: 16px;
  left: 20px;
  color: #2F3E68;
}

#log_detail .user_info_box p {
  color: #2F3E68;
  padding-left: 30px;
  line-height: 1.3rem;
}

#log_detail .date {
  position: relative;
  float: right;
  text-align: right;
  font-size: .9rem;
  top: -20px;
  color: rgba(47, 62, 104, 0.65);
}

#log_detail .grid_box {
  position: relative;
  width: 100%;
  display: -ms-grid;
  display: grid;
  margin-top: -30px;
  -ms-grid-columns: 1fr 2fr;
      grid-template-columns: 1fr 2fr;
  grid-column-gap: 30px;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
}

#log_detail .grid_box .log_detail_part {
  position: relative;
  width: 80%;
  max-width: 80%;
  overflow-wrap: break-word;
}

#log_detail .grid_box .log_detail_part h3 {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.8rem;
  color: #2F3E68;
  margin-bottom: 30px;
}

#log_detail .grid_box .log_detail_part .label {
  font-size: 1rem;
  font-weight: 500;
  color: #2F3E68;
}

#log_detail .grid_box .log_detail_part p {
  line-height: 1.7rem;
  color: rgba(47, 62, 104, 0.65);
}
