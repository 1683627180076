@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.user_documents_wrapper {
  position: relative;
  max-height: 22em;
  height: 100%;
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: -20px;
}

.user_documents_wrapper .user_documents_line {
  position: relative;
  height: auto;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  line-height: 1.1rem;
  margin-bottom: 16px;
  border-radius: 6px;
  padding: 12px 25px;
  width: 90%;
  left: 5%;
  cursor: pointer;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.user_documents_wrapper .user_documents_line:hover {
  -webkit-box-shadow: 0 10px 40px rgba(82, 147, 203, 0.22);
          box-shadow: 0 10px 40px rgba(82, 147, 203, 0.22);
}

.user_documents_wrapper .user_documents_line p {
  font-size: .85rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 400;
  margin-bottom: 0px;
  text-align: left;
  font-family: Roboto, sans-serif;
}

.user_documents_wrapper .user_documents_line .download_btn {
  position: absolute;
  right: 14px;
  height: 21px;
  width: 21px;
  border-radius: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-position: center;
  background-repeat: no-repeat;
  background-color: #25C873;
  -webkit-box-shadow: 0px 0px 14px rgba(37, 200, 115, 0.7);
          box-shadow: 0px 0px 14px rgba(37, 200, 115, 0.7);
  background-image: url(../../img/icons/download_icon.svg);
  background-size: 45%;
}

.user_documents_wrapper .empty_label {
  color: rgba(47, 62, 104, 0.4);
  text-align: center;
}
