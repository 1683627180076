@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
#user_detail {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 999;
  background: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  -webkit-transform: all .6s;
          transform: all .6s;
}

#user_detail .empty_label {
  position: relative;
  opacity: .8;
  text-align: center;
}

#user_detail.page {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

#user_detail .scroll_box {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 60px;
}

#user_detail .user_detail {
  position: absolute;
  top: 60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: auto;
  margin-bottom: 80px;
  padding-bottom: 80px;
  padding: 20px 50px !important;
}

#user_detail .user_status {
  font-size: 1rem;
  font-weight: 400;
  float: right;
  text-align: right;
}

#user_detail .user_status .user_status_inner {
  font-weight: 400;
  color: rgba(47, 62, 104, 0.65);
}

#user_detail .user_status .user_status_inner.ok {
  color: #25C873;
}

#user_detail .user_status .user_status_inner.active_user {
  color: #25C873;
}

#user_detail .user_status .user_status_inner.error {
  color: #FF7791;
}

#user_detail .page_switch_wrapper {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 30px;
  width: auto;
  height: 34px;
  background-color: #EEEEEE;
  border-radius: 80px;
  padding-right: 6px;
}

#user_detail .page_switch_wrapper .page {
  position: relative;
  height: 28px;
  top: 3px;
  left: 3px;
  padding: 0px 30px;
  display: inline-block;
  border-radius: 80px;
  cursor: pointer;
}

#user_detail .page_switch_wrapper .page p {
  color: rgba(47, 62, 104, 0.65);
  line-height: 28px;
  vertical-align: center;
  font-size: .8rem;
}

#user_detail .page_switch_wrapper .page.active_page {
  background-color: #FFF;
  -webkit-box-shadow: 0 10px 40px rgba(82, 147, 203, 0.22);
          box-shadow: 0 10px 40px rgba(82, 147, 203, 0.22);
}

#user_detail .page_switch_wrapper .page.active_page p {
  color: #2F3E68;
}

#user_detail .close_bnt {
  position: fixed;
  top: 40px;
  right: 40px;
  height: 30px;
  width: 30px;
  background-image: url("../../img/icons/close_btn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 110;
  cursor: pointer;
}

#user_detail .user_detail_grid {
  position: relative;
  width: 100%;
  height: 92%;
  margin-top: 60px;
  margin-bottom: 120px;
  display: -ms-grid;
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
}

#user_detail .user_detail_grid .user_detail_grid_right_side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 30px;
}

#user_detail .user_detail_grid .user_detail_grid_right_side .user_data_box {
  margin-left: 20px;
}

#user_detail .user_detail_grid .user_detail_grid_right_side .user_data_box h3 {
  font-weight: 500;
  color: #2F3E68;
  font-size: 1.4rem;
  margin-bottom: 20px;
}

#user_detail .user_detail_grid .user_detail_grid_right_side .user_data_box h2 {
  font-weight: 400;
  color: #2F3E68;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

#user_detail .user_detail_grid .user_detail_grid_right_side .user_data_box .info_grid {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 3fr;
      grid-template-columns: 1fr 3fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
}

#user_detail .user_detail_grid .user_detail_grid_right_side .user_data_box .info_grid p {
  font-size: .8rem;
  margin-bottom: 12px;
}

#user_detail .user_detail_grid .user_detail_grid_right_side .user_data_box .info_grid .label {
  color: rgba(47, 62, 104, 0.65);
  font-weight: 500;
  letter-spacing: 0px;
}

#user_detail .user_detail_grid .user_detail_grid_right_side .user_data_box .info_grid .data {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  color: #2F3E68;
}

#user_detail .user_detail_grid .user_detail_grid_right_side .paren_user .parent_grid {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  top: -14x;
  margin-bottom: 20px;
}

#user_detail .user_detail_grid .user_detail_grid_right_side .paren_user .parent_grid .user_info {
  position: relative;
  left: 30px;
}

#user_detail .user_detail_grid .user_detail_grid_right_side .paren_user .parent_grid .btn_wrapper {
  position: absolute;
  height: 30px;
  top: 15px;
  right: 30px;
}

#user_detail .user_detail_grid .user_detail_grid_right_side .remove_user .remove_user_btn {
  position: absolute;
  right: 30px;
  top: 25px;
  float: right;
}

#user_detail .user_detail_grid .user_detail_grid_left_side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 30px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper {
  margin-top: 0px;
  top: 0px;
  position: relative;
  height: auto;
  max-height: 100%;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper.account_detail {
  height: auto;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper.payout_info {
  height: auto;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper.user_identity {
  height: auto;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_account_detail {
  max-height: 360px;
  height: 360px !important;
  width: 100%;
  padding-left: 20px;
  min-height: 0%;
  max-height: 100%;
  margin-bottom: -100px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_account_detail .user_info {
  position: relative;
  left: 10px;
  top: 10px;
  height: 40px;
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_account_detail .user_info .profile_img {
  position: relative;
  width: 60px;
  height: 60px;
  max-height: 60px;
  max-width: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  border-radius: 100px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_account_detail .user_info p {
  font-family: Raleway, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  position: relative;
  top: 16px;
  left: 20px;
  color: #2F3E68;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_account_detail .account_balance {
  position: relative;
  font-family: 'Roboto Mono', monospace;
  font-size: 2.1rem;
  color: #2F3E68;
  margin-bottom: 15px;
  letter-spacing: 2px;
  font-weight: 600;
  margin-top: 40px;
  margin-left: 10px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_account_detail .po_grid {
  position: relative;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  height: auto;
  margin-bottom: 0px;
  margin-left: 10px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_account_detail .po_grid .prev_chart {
  position: relative;
  height: 100%;
  width: 95%;
  left: 5%;
  top: -20px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_account_detail .po_grid .circular_progressbar_box {
  position: relative;
  width: auto;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_account_detail .po_grid .circular_progressbar_box .circular_progressbar {
  width: 80px;
  height: 80px;
  font-family: Roboto;
  font-weight: 200;
  display: inline-block;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_account_detail .po_grid .circular_progressbar_box .textbox {
  position: relative;
  display: inline-block;
  height: auto;
  left: 10px;
  top: -10px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_account_detail .po_grid .circular_progressbar_box .textbox p {
  line-height: 1.2rem;
  font-size: .8rem;
  position: relative;
  margin: 0px;
  padding: 0px;
  color: #2F3E68;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_account_detail .po_grid .circular_progressbar_box .textbox .amount {
  font-family: 'Roboto Mono', monospace;
  font-weight: 600;
  line-height: 1.2rem;
  font-size: 1rem;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_account_detail .po_grid .circular_progressbar_box .textbox .amount_label {
  font-family: Roboto;
  font-weight: 300;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_payout_info .interest_amount {
  font-size: 1.6rem;
  color: #2F3E68;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_payout_info .interest_calc {
  position: relative;
  padding-left: 30px;
  top: -10px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .container_wrapper .udt_payout_info .switch_container {
  pointer-events: none;
  opacity: .8;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .user_identity_docs .user_file_grid {
  padding: 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .user_identity_docs .user_file_grid .grid_item_prew {
  height: auto;
  width: 100%;
  border-radius: 8px;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
}

#user_detail .user_detail_grid .user_detail_grid_left_side .change_pass .change_pass_btn {
  float: right;
  position: relative;
  right: 30px;
  margin-top: -25px;
  margin-bottom: 10px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .child_users .child_users_grid {
  display: -ms-grid;
  display: grid;
  grid-row-gap: 16px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .child_users .child_users_grid .inv_line_box {
  position: relative;
  left: 2%;
  width: 96%;
  height: 46px;
  -webkit-box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
          box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
  border-radius: 6px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  padding: 2px 15px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .child_users .child_users_grid .inv_line_box p {
  font-family: Roboto;
  padding-left: 4%;
  font-weight: 300;
  font-size: .8rem;
  margin-bottom: 0px !important;
  color: #2F3E68;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .child_users .child_users_grid .itw_line {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: 96%;
  left: 2%;
  border: 1px solid  #ccc;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  height: 25px;
  line-height: 25px;
  vertical-align: center;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .child_users .child_users_grid .itw_line p {
  font-family: Roboto;
  font-weight: 300;
  font-size: .8rem;
  padding-left: 4%;
  color: #2F3E68;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .child_users .child_users_grid .itw_line p.currency {
  font-size: .8rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .child_users .child_users_grid .itw_line p.move_up {
  color: #25C873;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .child_users .child_users_grid .itw_line p.move_down {
  color: #FF7791;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .child_users .child_users_grid .line_legend {
  border-bottom: 1px solid #2F3E68;
  height: 25px;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

#user_detail .user_detail_grid .user_detail_grid_left_side .child_users .child_users_grid .line_legend p {
  font-family: 'Roboto Mono';
  font-weight: 600;
  font-size: .8rem;
  padding-left: 4%;
  color: rgba(47, 62, 104, 0.65);
}

#user_detail .user_detail_grid .user_info_page2 {
  position: absolute;
  left: 10px;
  top: -130px;
  height: 40px;
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#user_detail .user_detail_grid .user_info_page2 .profile_img {
  position: relative;
  width: 60px;
  height: 60px;
  max-height: 60px;
  max-width: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  border-radius: 100px;
}

#user_detail .user_detail_grid .user_info_page2 p {
  font-family: Raleway, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  position: relative;
  top: 16px;
  left: 20px;
  color: #2F3E68;
}
