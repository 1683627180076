@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
#change_parent {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 999;
  background: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  -webkit-transform: all .6s;
          transform: all .6s;
}

#change_parent.page {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

#change_parent .change_parent_box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-top: 4px;
  margin-left: 4px;
  height: 50vh;
  width: 40vw;
}

#change_parent .center_buttons_grid {
  position: absolute;
  width: 70%;
  left: 15%;
  bottom: 0px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

#change_parent .change_parent_text {
  position: relative;
  top: 20px;
}

#change_parent .select_user_wrapper {
  position: relative;
  height: 34vh;
  max-height: 34vh;
  width: 100%;
  top: 30px;
  overflow-y: auto;
}

#change_parent .select_user_wrapper .select_user_grid {
  display: -ms-grid;
  display: grid;
  grid-row-gap: 16px;
  padding-bottom: 30px;
  width: 95%;
  left: 2.5%;
  position: relative;
}

#change_parent .select_user_wrapper .select_user_grid .inv_line_box {
  position: relative;
  left: 2%;
  width: 96%;
  height: 46px;
  -webkit-box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
          box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
  border-radius: 6px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: .8fr 1fr;
      grid-template-columns: .8fr 1fr;
  padding: 2px 15px;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
}

#change_parent .select_user_wrapper .select_user_grid .inv_line_box.selected {
  -webkit-box-shadow: 0 6px 30px rgba(0, 186, 255, 0.3);
          box-shadow: 0 6px 30px rgba(0, 186, 255, 0.3);
  border: 1px solid #27DEFF;
}

#change_parent .select_user_wrapper .select_user_grid .inv_line_box:hover {
  -webkit-box-shadow: 0 6px 30px rgba(0, 186, 255, 0.3);
          box-shadow: 0 6px 30px rgba(0, 186, 255, 0.3);
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

#change_parent .select_user_wrapper .select_user_grid .inv_line_box p {
  font-family: Roboto;
  padding-left: 4%;
  font-weight: 300;
  font-size: .8rem;
  margin-bottom: 0px !important;
  color: #2F3E68;
}

#change_parent .select_user_wrapper .select_user_grid .itw_line {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: 96%;
  left: 2%;
  border: 1px solid  #ccc;
  -ms-grid-columns: .8fr 1fr;
      grid-template-columns: .8fr 1fr;
  height: 25px;
  line-height: 25px;
  vertical-align: center;
}

#change_parent .select_user_wrapper .select_user_grid .itw_line p {
  font-family: Roboto;
  font-weight: 300;
  font-size: .8rem;
  padding-left: 4%;
  color: #2F3E68;
}

#change_parent .select_user_wrapper .select_user_grid .itw_line p.currency {
  font-size: .8rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
}

#change_parent .select_user_wrapper .select_user_grid .itw_line p.move_up {
  color: #25C873;
}

#change_parent .select_user_wrapper .select_user_grid .itw_line p.move_down {
  color: #FF7791;
}

#change_parent .select_user_wrapper .select_user_grid .line_legend {
  border-bottom: 1px solid #2F3E68;
  height: 25px;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

#change_parent .select_user_wrapper .select_user_grid .line_legend p {
  font-family: 'Roboto Mono';
  font-weight: 600;
  font-size: .8rem;
  padding-left: 4%;
  color: rgba(47, 62, 104, 0.65);
}
