@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
#change_user_perm {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 999;
  background: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  -webkit-transform: all .6s;
          transform: all .6s;
}

#change_user_perm.page {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

#change_user_perm .change_user_perm_box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 40vh;
}

#change_user_perm .edit_account_btn {
  float: right;
  min-width: 45%;
  margin-bottom: 10px;
}

#change_user_perm .account_edit_item {
  margin-top: 5px;
}

#change_user_perm .account_edit_item .input {
  width: 100%;
  max-width: 100%;
}

#change_user_perm .cb_label_right_large {
  position: relative;
  right: 10px;
  float: right;
  top: 10px;
  text-align: right;
  font-size: 1.4rem;
  font-family: Raleway, sans-serif;
  font-weight: 500;
}

#change_user_perm .close_bnt {
  position: fixed;
  top: 40px;
  right: 40px;
  height: 30px;
  width: 30px;
  background-image: url("../../img/icons/close_btn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 110;
  cursor: pointer;
}

#change_user_perm .user_info_page {
  position: relative;
  left: 10%;
  top: 0px;
  height: 40px;
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#change_user_perm .user_info_page .profile_img {
  position: relative;
  width: 60px;
  height: 60px;
  max-height: 60px;
  max-width: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  border-radius: 100px;
}

#change_user_perm .user_info_page p {
  font-family: Raleway, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  position: relative;
  top: 16px;
  left: 20px;
  color: #2F3E68;
}

#change_user_perm .progress-bar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 400px;
  top: 80px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

#change_user_perm .step {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  z-index: 1;
  display: block;
  width: 25px;
  height: 25px;
  margin-bottom: 30px;
  border: 4px solid #fff;
  border-radius: 50%;
  background-color: #efefef;
  cursor: pointer;
  -webkit-transition: all .4s;
  transition: all .4s;
}

#change_user_perm .step:hover {
  -webkit-box-shadow: 0 6px 30px rgba(0, 186, 255, 0.3);
          box-shadow: 0 6px 30px rgba(0, 186, 255, 0.3);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

#change_user_perm .step:after {
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 22px;
  width: 160px;
  height: 6px;
  content: '';
  background-color: #efefef;
}

#change_user_perm .step:before {
  color: rgba(47, 62, 104, 0.65);
  position: absolute;
  top: 40px;
}

#change_user_perm .step:last-child:after {
  content: none;
}

#change_user_perm .step.active0 {
  background-color: #27DEFF;
}

#change_user_perm .step.active0:before {
  color: #27DEFF;
}

#change_user_perm .step.active {
  background-color: #27DEFF;
}

#change_user_perm .step.active:after {
  background-color: #27DEFF;
}

#change_user_perm .step.active:before {
  color: #27DEFF;
}

#change_user_perm .step.active + .step {
  background-color: #27DEFF;
}

#change_user_perm .step.active + .step:before {
  color: #27DEFF;
}

#change_user_perm .step:nth-child(1):before {
  content: 'uživatel';
}

#change_user_perm .step:nth-child(2):before {
  right: -40px;
  content: 'obchodník';
}

#change_user_perm .step:nth-child(3):before {
  right: 0;
  content: 'admin';
}
