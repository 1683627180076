@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.documents_component .tr_header_grid {
  position: relative;
  width: 70%;
  left: 3%;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
  height: auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
}

.documents_component .tr_header_grid .grid_item {
  position: relative;
}

.documents_component .tr_header_grid .grid_item p {
  font-size: .95rem;
  font-family: Roboto, sans-serif;
  color: #2F3E68;
  font-weight: 400;
  margin-bottom: 10px;
}

.documents_component .tr_header_grid .grid_item p.legend {
  color: rgba(47, 62, 104, 0.65);
  font-weight: 600;
  font-size: .8rem;
}

.documents_component .main_content_box .trd_line {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: 96%;
  left: 2%;
  border: 1px solid  #ccc;
  -ms-grid-columns: .9fr 2.5fr 1fr 1fr .1fr;
      grid-template-columns: .9fr 2.5fr 1fr 1fr .1fr;
  height: 25px;
  line-height: 25px;
  vertical-align: center;
}

.documents_component .main_content_box .trd_line p {
  font-family: Roboto;
  font-weight: 300;
  font-size: .8rem;
  color: #2F3E68;
}

.documents_component .main_content_box .trd_line p.currency {
  font-size: .8rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
}

.documents_component .main_content_box .line_legend {
  border-bottom: 1px solid #2F3E68;
  height: 25px;
  margin-bottom: 5px;
  padding-left: 35px;
  padding-right: 35px;
}

.documents_component .main_content_box .line_legend p {
  font-family: 'Roboto Mono';
  font-weight: 600;
  font-size: .85rem;
  color: #2F3E68;
}

.documents_component .main_content_box .empty_label {
  color: rgba(47, 62, 104, 0.4);
  text-align: center;
  position: relative;
  margin-top: 40px;
}

.documents_component .main_content_box .trd_line_box_wrapper {
  position: relative;
  padding-top: 14px;
  padding-bottom: 14px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  width: 100%;
  height: auto;
  grid-row-gap: 16px;
}

.documents_component .main_content_box .trd_line_box_wrapper .trd_line_box {
  position: relative;
  left: 2%;
  width: 96%;
  height: 46px;
  -webkit-box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
          box-shadow: 0 4px 20px rgba(82, 147, 203, 0.18);
  background-color: #FFF;
  border-radius: 6px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: .9fr 2.5fr 1fr 1fr .1fr;
      grid-template-columns: .9fr 2.5fr 1fr 1fr .1fr;
  padding: 2px 35px;
}

.documents_component .main_content_box .trd_line_box_wrapper .trd_line_box.tpye0 {
  border-left: 5px solid #25C873;
}

.documents_component .main_content_box .trd_line_box_wrapper .trd_line_box.tpye1 {
  border-left: 5px solid #27DEFF;
}

.documents_component .main_content_box .trd_line_box_wrapper .trd_line_box.tpye2 {
  border-left: 5px solid #FF7791;
}

.documents_component .main_content_box .trd_line_box_wrapper .trd_line_box p {
  font-family: Roboto;
  font-weight: 400;
  font-size: .8rem;
  margin-bottom: 0px !important;
  color: #2F3E68;
}

.documents_component .main_content_box .trd_line_box_wrapper .trd_line_box p.move_up {
  color: #25C873;
}

.documents_component .main_content_box .trd_line_box_wrapper .trd_line_box p.move_down {
  color: #FF7791;
}

.documents_component .main_content_box .trd_line_box_wrapper .trd_line_box p.currency {
  font-size: .85rem;
  color: #2F3E68;
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
}

.documents_component .main_content_box .download_btn {
  position: absolute;
  right: 14px;
  height: 21px;
  width: 21px;
  border-radius: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-position: center;
  background-repeat: no-repeat;
  background-color: #25C873;
  -webkit-box-shadow: 0px 0px 14px rgba(37, 200, 115, 0.7);
          box-shadow: 0px 0px 14px rgba(37, 200, 115, 0.7);
  background-image: url(../../img/icons/download_icon.svg);
  background-size: 45%;
}
