@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
#investment_detail {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 999;
  background: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  -webkit-transform: all .6s;
          transform: all .6s;
}

#investment_detail.page {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

#investment_detail .manual_account_manager {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 75vh;
}

#investment_detail .edit_account_btn {
  float: right;
  min-width: 40%;
  margin-bottom: 10px;
}

#investment_detail .account_edit_item {
  margin-top: 5px;
}

#investment_detail .account_edit_item .input {
  width: 100%;
  max-width: 100%;
}

#investment_detail .cb_label_right {
  margin-top: -10px;
  top: 10px;
  position: relative;
}

#investment_detail .acm_line_box_wrapper .search_input {
  margin-left: 10px;
  margin-bottom: -20px;
  margin-top: -8px;
}

#investment_detail .acm_line_box_wrapper .search_input .input {
  width: 50%;
}

#investment_detail .close_bnt {
  position: fixed;
  top: 40px;
  right: 40px;
  height: 30px;
  width: 30px;
  background-image: url("../../img/icons/close_btn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 110;
  cursor: pointer;
}

#investment_detail .invDetailContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  width: 100%;
  height: auto;
  padding: 30px;
}

#investment_detail .invDetailContainer .invDetailLabel {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px;
}

#investment_detail .invDetailContainer .invDetailValue {
  font-size: 1rem;
  margin-bottom: 10px;
  font-weight: 300;
}
