@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.portfolio_overiview .cb_label_right {
  position: absolute;
  top: 15px;
  right: 20px;
}

.portfolio_overiview .portfolio_overiview_conainer {
  padding-left: 30px;
  padding-right: 5px;
  position: relative;
  width: 100%;
  height: 100%;
}

.portfolio_overiview .portfolio_overiview_conainer .client_overview_grid {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  height: 80px;
  max-width: 96%;
  grid-column-gap: 30px;
  margin-top: 50px;
}

.portfolio_overiview .portfolio_overiview_conainer .client_overview_grid .grid_content_box {
  position: relative;
  width: 100%;
  height: auto;
  -webkit-box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
          box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  background-color: #fff;
  border-radius: 6px;
  min-height: 30px;
  padding: 10px 20px;
}

.portfolio_overiview .portfolio_overiview_conainer .client_overview_grid .grid_content_box.type1 {
  border-left-width: 4px;
  border-style: solid;
  -o-border-image: linear-gradient(to bottom, #4FEDED, #009DFF) 1 100%;
     border-image: -webkit-gradient(linear, left top, left bottom, from(#4FEDED), to(#009DFF)) 1 100%;
     border-image: linear-gradient(to bottom, #4FEDED, #009DFF) 1 100%;
}

.portfolio_overiview .portfolio_overiview_conainer .client_overview_grid .grid_content_box.type2 {
  border-left-width: 4px;
  border-style: solid;
  -o-border-image: linear-gradient(to bottom, #71E7FF, #A95BFD) 1 100%;
     border-image: -webkit-gradient(linear, left top, left bottom, from(#71E7FF), to(#A95BFD)) 1 100%;
     border-image: linear-gradient(to bottom, #71E7FF, #A95BFD) 1 100%;
}

.portfolio_overiview .portfolio_overiview_conainer .client_overview_grid .grid_content_box.type3 {
  border-left-width: 4px;
  border-style: solid;
  -o-border-image: linear-gradient(to bottom, #E7B2FF, #7820D8) 1 100%;
     border-image: -webkit-gradient(linear, left top, left bottom, from(#E7B2FF), to(#7820D8)) 1 100%;
     border-image: linear-gradient(to bottom, #E7B2FF, #7820D8) 1 100%;
}

.portfolio_overiview .portfolio_overiview_conainer .client_overview_grid .grid_content_box .stat_h3 {
  position: relative;
  font-size: 1.7rem;
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
  color: #2F3E68;
}

.portfolio_overiview .portfolio_overiview_conainer .client_overview_grid .grid_content_box .legend {
  position: relative;
  font-size: .9rem;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(47, 62, 104, 0.65);
}

.portfolio_overiview .acount_balance {
  position: relative;
  font-family: 'Roboto Mono', monospace;
  font-size: 2.6rem;
  color: #2F3E68;
  margin-bottom: 15px;
  letter-spacing: 2px;
  font-weight: 600;
  margin-top: 10px;
}

.portfolio_overiview .po_grid {
  position: relative;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr .7fr;
      grid-template-columns: 1fr 1fr .7fr;
  height: 45%;
  margin-bottom: -60px;
  z-index: 25;
}

.portfolio_overiview .po_grid .prev_chart {
  position: relative;
  height: 100%;
  width: 100%;
  left: -5%;
  top: -20px;
}

.portfolio_overiview .po_grid .circular_progressbar_box {
  position: relative;
  width: auto;
}

.portfolio_overiview .po_grid .circular_progressbar_box .circular_progressbar {
  width: 80px;
  height: 80px;
  font-family: Roboto;
  font-weight: 200;
  display: inline-block;
}

.portfolio_overiview .po_grid .circular_progressbar_box .textbox {
  position: relative;
  display: inline-block;
  height: auto;
  left: 10px;
  top: -22%;
}

.portfolio_overiview .po_grid .circular_progressbar_box .textbox p {
  line-height: 1.2rem;
  font-size: .8rem;
  position: relative;
  margin: 0px;
  padding: 0px;
  color: #2F3E68;
}

.portfolio_overiview .po_grid .circular_progressbar_box .textbox .amount {
  font-family: 'Roboto Mono', monospace;
  font-weight: 600;
  line-height: 1.2rem;
  font-size: 1rem;
}

.portfolio_overiview .po_grid .circular_progressbar_box .textbox .amount_label {
  font-family: Roboto;
  font-weight: 300;
}

.portfolio_overiview .transaction_history_previw_wrapper {
  position: relative;
  width: calc( 100% - 180px);
  border: 1px solid  #ccc;
  height: auto;
  margin-top: 6%;
}

.portfolio_overiview .transaction_history_previw_wrapper .thp_line {
  display: -ms-grid;
  display: grid;
  width: 100%;
  border: 1px solid  #ccc;
  -ms-grid-columns: 1fr 1.3fr .6fr .9fr;
      grid-template-columns: 1fr 1.3fr .6fr .9fr;
  height: 25px;
  line-height: 25px;
  vertical-align: canter;
}

.portfolio_overiview .transaction_history_previw_wrapper .thp_line p {
  font-family: Roboto;
  font-weight: 300;
  font-size: .8rem;
  color: #2F3E68;
}

.portfolio_overiview .transaction_history_previw_wrapper .thp_line p.currency {
  font-size: .8rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 500;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0px;
}

.portfolio_overiview .transaction_history_previw_wrapper .thp_line p.move_up {
  color: #25C873;
}

.portfolio_overiview .transaction_history_previw_wrapper .thp_line p.move_down {
  color: #FF7791;
}

.portfolio_overiview .transaction_history_previw_wrapper .line_legend {
  border-bottom: 1px solid #2F3E68;
  height: 25px;
  margin-bottom: 5px;
}

.portfolio_overiview .transaction_history_previw_wrapper .line_legend p {
  font-family: 'Roboto Mono';
  font-weight: 600;
  font-size: .8rem;
  color: rgba(47, 62, 104, 0.65);
}

.portfolio_overiview .withdraw_account_btn {
  position: absolute;
  bottom: -20px;
  right: 10px;
}
