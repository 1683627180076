@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  letter-spacing: 1px;
}

h1, h2, h3, h4 {
  font-family: Raleway, sans-serif;
  font-weight: 400;
  color: rgba(47, 62, 104, 0.65);
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.4rem;
}

article, aside, figure, footer, header, main, nav, section {
  display: block;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

*:before, *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*::after, *::before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
}

/**
 *    MAIN RULES */
body {
  background-color: #FAFAFA;
  min-height: 100%;
  overflow-x: hidden;
  position: relative;
}

p {
  font-weight: normal;
  margin-bottom: 1.5em;
}

img {
  max-width: 100%;
}

strong {
  font-weight: 600;
}

ul {
  margin-bottom: 1em;
}

li {
  list-style: none;
  margin-bottom: 0.5em;
}

/**
 *    BACKGROUND */
.bg-primary {
  background: -webkit-gradient(linear, right top, left top, from(#4FEDED), to(#009DFF));
  background: linear-gradient(-90deg, #4FEDED, #009DFF);
}

.bg-dark {
  background-color: #18232f;
}

.bg-secondary {
  background-color: #e91e63;
}

.bg-white {
  background-color: #fff;
}

.bg-success {
  background-color: #4caf50;
}

.bg-info {
  background-color: #5bc0de;
}

.bg-warning {
  background-color: #f0ad4e;
}

.bg-error {
  background-color: #e74c3c;
}

.bg-gray {
  background-color: #969da6;
}

.bg-gray-light {
  background-color: #eceff1;
}

/**
 *    BORDER */
.border {
  border: 1px solid #d5d9db;
}

.border-bottom {
  border-bottom: 1px solid #d5d9db;
}

.border-left {
  border-left: 1px solid #d5d9db;
}

.border-right {
  border-right: 1px solid #d5d9db;
}

.border-top {
  border-top: 1px solid #d5d9db;
}

/**
 *    ALIGNERS */
.aligner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.aligner--spaceBetween {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.aligner--spaceAround {
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100%;
}

.aligner--centerVertical {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.aligner--centerHoritzontal {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.aligner--contentStart {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.aligner--contentEnd {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.aligner--itemTop {
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.aligner--itemBottom {
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.flex-grow, .aligner--grow {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

/**
 *    FLOATS */
.fleft {
  float: left;
}

.fright {
  float: right;
}

.clearfix ::after {
  clear: both;
  content: '';
  display: table;
}

/**
 *    MARGINS */
.m-xsmall {
  margin: 4px;
}

.mb-xsmall {
  margin-bottom: 4px;
}

.m-small {
  margin: 8px;
}

.mb-small {
  margin-bottom: 8px;
}

.m-medium {
  margin: 16px;
}

.mb-medium {
  margin-bottom: 16px;
}

.m-big {
  margin: 36px;
}

.mb-big {
  margin-bottom: 36px;
}

.m-huge {
  margin: 48px;
}

.mb-huge {
  margin-bottom: 48px;
}

.m-none {
  margin: 0 !important;
}

/**
 *    PADDINGS */
.p-small {
  padding: 4px;
}

.pb-small {
  padding-bottom: 4px;
}

.p-medium {
  padding: 8px;
}

.pb-medium {
  padding-bottom: 8px;
}

.p-big {
  padding: 16px;
}

.pb-big {
  padding-bottom: 16px;
}

.p-huge {
  padding: 36px;
}

.pb-huge {
  padding-bottom: 36px;
}

/**
 *    OTHERS */
.no-wrap {
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.opacity-low {
  opacity: .5;
}

.rounded-corners {
  border-radius: 5px;
}

.rounded {
  border-radius: 100%;
}

/**
 * LAYOUT */
.section {
  padding-bottom: 36px;
  padding-top: 36px;
}

.section + .section {
  padding-top: 0;
}

@media (min-width: 768px) {
  .section {
    padding-bottom: 72px;
    padding-top: 72px;
  }
}

.container {
  background-color: transparent;
  margin: 0 auto;
  max-width: 1380px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
}

@media (min-width: 768px) {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.container-medium {
  margin: 0 auto;
  max-width: 944px;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 768px) {
  .container-medium {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.container-small {
  margin: 0 auto;
  max-width: 400px;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 768px) {
  .container-small {
    padding-left: 24px;
    padding-right: 24px;
  }
}

/**
 *    TYPOGRAPHY */
body {
  color: #2F3E68;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

a {
  color: #2F3E68;
  text-decoration: none;
}

a:hover {
  color: rgba(3, 169, 244, 0.8);
}

a:focus {
  color: #2F3E68;
}

.text-huge, .text-big, .text-medium {
  margin-bottom: 1em;
}

.text-huge {
  font-size: 36px;
  line-height: 1.3em;
}

.text-big {
  font-size: 24px;
  line-height: 1.3em;
}

.text-medium {
  font-size: 16px;
  line-height: 1.5em;
}

.text-small {
  font-size: 12px;
  line-height: 1.3em;
}

.text-body {
  font-size: 16px;
  line-height: 1.5em;
}

.text-primary {
  color: #2F3E68;
}

.text-dark {
  color: #18232f;
}

.text-secondary {
  color: #e91e63;
}

.text-white {
  color: #fff;
}

.text-success {
  color: #4caf50;
}

.text-info {
  color: #5bc0de;
}

.text-warning {
  color: #f0ad4e;
}

.text-error {
  color: #e74c3c;
}

.text-gray {
  color: #969da6;
}

.text-gray-light {
  color: #eceff1;
}

.text-light {
  font-weight: 300;
}

.text-normal {
  font-weight: 400;
}

.text-lineThrough {
  text-decoration: line-through;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-withSubtitle {
  margin-bottom: 0 !important;
}

.text-withSubtitle + .text-huge, .text-withSubtitle + .text-big, .text-withSubtitle + .text-medium, .text-withSubtitle + .text-small {
  margin-top: .5em;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/**
 *    BADGE
 *
 *    Markup:
 *    -------
 *
 *    <ul>
 *        <li class="badge badge--primary">Badge 1</li>
 *        <li class="badge badge--secondary badge--big">Badge 1</li>
 *    </ul>
 * */
.badge {
  background-color: #eceff1;
  border-radius: 3px;
  color: #2F3E68;
  display: inline-block;
  line-height: 1.2em;
  padding: 8px 16px;
}

.badge--big {
  font-size: 1.3em;
}

.badge--small {
  font-size: 0.7em;
}

.badge--primary {
  background: -webkit-gradient(linear, right top, left top, from(#4FEDED), to(#009DFF));
  background: linear-gradient(-90deg, #4FEDED, #009DFF);
  color: #fff;
}

.badge--secondary {
  background-color: #e91e63;
  color: #fff;
}

.badge--dark {
  background-color: #18232f;
  color: #fff;
}

.badge--light {
  background-color: #969da6;
  color: #fff;
}

.badge--success {
  background-color: #4caf50;
  color: #fff;
}

.badge--error {
  background-color: #e74c3c;
  color: #fff;
}

.badge--warning {
  background-color: #f0ad4e;
  color: #fff;
}

/**
 *    BUTTON
 *
 *    Markup:
 *    -------
 *
 *    <button class="button">Default</button>
 *    <button class="button button--big">Button big</button>
 *    <button class="button button--secondary">Button secondary</button>
 * */
button {
  background-color: transparent;
  cursor: pointer;
}

.button {
  background: -webkit-gradient(linear, right top, left top, from(#4FEDED), to(#009DFF));
  background: linear-gradient(-90deg, #4FEDED, #009DFF);
  border: 0;
  border-radius: 200px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  letter-spacing: 1.4px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  margin: 0 7px 35px 7px;
  padding: 7px 35px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  white-space: nowrap;
  -webkit-box-shadow: 0 6px 20px rgba(0, 128, 207, 0.45);
          box-shadow: 0 6px 20px rgba(0, 128, 207, 0.45);
}

.button:focus, .button:hover, .button:active {
  color: #fff;
}

.button:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
  -webkit-box-shadow: 0 2px 30px rgba(0, 103, 166, 0.35);
          box-shadow: 0 2px 30px rgba(0, 103, 166, 0.35);
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.button:active {
  opacity: 1;
}

.button.inactive {
  opacity: .6;
  cursor: wait;
  pointer-events: none;
}

.button:first-child {
  margin-left: 0;
}

.button:last-child {
  margin-right: 0;
}

.button--big {
  font-size: 24px;
  padding: 16px 36px;
}

.button--small {
  font-size: 12px;
  padding: 4px 36px;
}

@media (max-width: 991px) {
  .button--mobileFull {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

.button--secondary {
  background: #e91e63;
  color: #fff;
}

.button--secondary:hover {
  background: rgba(233, 30, 99, 0.8);
  color: #fff;
}

.button--white {
  background-color: #fff;
  color: #2F3E68;
}

.button--white:hover {
  background: rgba(255, 255, 255, 0.8);
  color: rgba(3, 169, 244, 0.8);
}

.button--green {
  background: #4caf50;
  color: #fff;
}

.button--green:hover {
  background: rgba(76, 175, 80, 0.8);
  color: #fff;
}

.button--red {
  background: -webkit-gradient(linear, right top, left top, from(#BE0000), to(#FF7791));
  background: linear-gradient(-90deg, #BE0000, #FF7791);
  color: #fff;
  -webkit-box-shadow: 0 6px 20px rgba(231, 76, 60, 0.17);
          box-shadow: 0 6px 20px rgba(231, 76, 60, 0.17);
}

.button--red:hover {
  background: -webkit-gradient(linear, right top, left top, from(#9b0000), to(#cf2645));
  background: linear-gradient(-90deg, #9b0000, #cf2645);
  color: #fff;
  -webkit-box-shadow: 0 6px 20px rgba(231, 76, 60, 0.47);
          box-shadow: 0 6px 20px rgba(231, 76, 60, 0.47);
}

.button--transparent {
  background: transparent;
  color: #2F3E68;
}

.button--transparent:active, .button--transparent:hover, .button--transparent:focus {
  background: transparent;
  color: rgba(8, 8, 8, 0.8);
  opacity: .8;
}

.button--outlined {
  background: transparent;
  border: 1px solid #2F3E68;
  color: #2F3E68;
  -webkit-box-shadow: 0 6px 20px rgba(33, 98, 220, 0.17);
          box-shadow: 0 6px 20px rgba(33, 98, 220, 0.17);
}

.button--outlined:active, .button--outlined:hover, .button--outlined:focus {
  background: transparent;
  color: #2F3E68;
  border: 1px solid #2F3E68;
}

/**
 *    FORMS
 *
 *    Markup:
 *    ---------
 *    <div class="input input-fullWidth">
 *        <input id="#" placeholder="#" type="text">
 *    </div>
 *
 *    <div class="select select-fullWidth">
 *        <select name="#" id="#">
 *            <option>Option 1</option>
 *            <option>Option 2</option>
 *        </select>
 *    </div>
 *
 *    <div class="textarea">
 *        <textarea id="#"></textarea>
 *    </div>
 *
 *    <div class="radio">
 *        <input id="#" name="#" type="radio" value="">
 *        <label for="#">Radio button</label>
 *    </div>
 *
 *    <div class="checkbox">
 *        <input id="#" name="#" type="checkbox" value="">
 *        <label for="#">Checkbox</label>
 *    </div> */
input, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

label {
  display: block;
  font-weight: normal;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #FFF inset;
          box-shadow: 0 0 0 1000px #FFF inset;
}

.textarea, .input, .select {
  border: 1px solid rgba(47, 62, 104, 0.2);
  border-radius: 5px;
  display: inline-block;
  font-weight: normal;
  margin-bottom: 20px;
  overflow: hidden;
  -webkit-box-shadow: 0 6px 20px rgba(33, 98, 220, 0.17);
          box-shadow: 0 6px 20px rgba(33, 98, 220, 0.17);
  -webkit-transition: all .1s;
  transition: all .1s;
}

.textarea :focus, .input :focus, .select :focus {
  outline: none;
  border-left: 3px solid rgba(17, 58, 136, 0.8);
  -webkit-box-shadow: 0 2px 30px rgba(0, 186, 255, 0.3) !important;
          box-shadow: 0 2px 30px rgba(0, 186, 255, 0.3) !important;
}

.textarea.has-error, .input.has-error {
  background: #FFF;
  border: 1px solid #e74c3c;
  margin-bottom: 0;
}

.textarea.blue, .input.blue {
  background: #FFF;
  border: 1px solid #00BAFF;
  margin-bottom: 0;
}

.select {
  background-color: #FFF;
  display: inline-block;
  margin-right: 16px;
  position: relative;
}

.select.has-error {
  background: #FFF;
  border: 1px solid #e74c3c;
  -webkit-box-shadow: 0 6px 20px rgba(231, 76, 60, 0.17) !important;
          box-shadow: 0 6px 20px rgba(231, 76, 60, 0.17) !important;
  margin-bottom: 0;
}

.select:last-child {
  margin-right: 0;
}

.select-fullWidth {
  display: block;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: #2F3E68;
  display: block;
  font-size: 16px;
  line-height: 1.5em;
  margin: 0;
  padding: 8px 16px;
  padding-right: 30px;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
}

.select select:active, .select select:focus {
  background-color: #fbfbfc;
  border: 0;
  outline: none;
  -webkit-box-shadow: 0px 2px 15px rgba(82, 199, 239, 0.2);
          box-shadow: 0px 2px 15px rgba(82, 199, 239, 0.2);
}

.select select::-ms-expand {
  display: none;
}

.select::after, .select::before {
  background: #2F3E68;
  content: '';
  display: block;
  height: 2px;
  margin-top: 2px;
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform-origin: 1px;
  transform-origin: 1px;
  width: 10px;
}

.select::after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.select::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.textarea {
  background-color: #FFF;
  padding: 0;
}

.textarea-fullWidth {
  display: block;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.textarea textarea {
  background: transparent;
  border: 0;
  color: #2F3E68;
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 1.5px;
  margin: 0;
  min-height: 120px;
  padding: 8px 16px;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
  resize: none;
  -webkit-transition: all .1s;
  transition: all .1s;
}

.textarea textarea::-webkit-input-placeholder, .textarea textarea::-ms-input-placeholder, .textarea textarea::-webkit-input-placeholder {
  color: #969da6;
}

.textarea textarea::-webkit-input-placeholder, .textarea textarea::-ms-input-placeholder, .textarea textarea::placeholder {
  color: #969da6;
}

.textarea textarea:focus, .textarea textarea:active {
  background-color: #FFF;
  outline: none;
}

.checkbox {
  margin-bottom: 8px;
  position: relative;
}

.checkbox input[type='checkbox'] {
  display: none;
}

.checkbox input[type='checkbox']:checked + label::after {
  -webkit-animation: checkboxAndRadioAnimation .25s;
  animation: checkboxAndRadioAnimation .25s;
  content: '';
  -webkit-transform: scale(1) rotate(45deg);
  transform: scale(1) rotate(45deg);
  -webkit-box-shadow: 0px 2px 15px rgba(82, 199, 239, 0.2);
          box-shadow: 0px 2px 15px rgba(82, 199, 239, 0.2);
}

.checkbox input[type='checkbox'] + label {
  display: block;
  overflow: hidden;
  padding-left: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkbox input[type='checkbox'] + label::before {
  background-color: #FFF;
  border: 1px solid #d5d9db;
  border-radius: 0px;
  content: '';
  display: inline-block;
  height: 20px;
  left: 0;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 20px;
  -webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.checkbox input[type='checkbox'] + label::after {
  border-bottom: 3px solid #2F3E68;
  border-right: 3px solid #2F3E68;
  display: block;
  height: 12px;
  left: 11px;
  margin-left: -4px;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  width: 7px;
  z-index: 1;
}

.radio {
  margin-bottom: 8px;
  position: relative;
}

.radio input[type='radio'] {
  display: none;
}

.radio input[type='radio']:checked + label::after {
  -webkit-animation: checkboxAndRadioAnimation .25s;
  animation: checkboxAndRadioAnimation .25s;
  content: '';
  -webkit-transform: scale(1) rotate(45deg);
  transform: scale(1) rotate(45deg);
}

.radio input[type='radio'] + label {
  display: block;
  overflow: hidden;
  padding-left: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.radio input[type='radio'] + label::before {
  background-color: #FFF;
  border: 1px solid #d5d9db;
  border-radius: 20px;
  content: '';
  -webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  display: inline-block;
  height: 20px;
  left: 0;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 20px;
}

.radio input[type='radio'] + label::after {
  background-color: #2F3E68;
  border-radius: 20px;
  display: block;
  height: 10px;
  left: 11px;
  margin-left: -6px;
  margin-top: -6px;
  position: absolute;
  top: 13px;
  width: 10px;
  z-index: 1;
}

@-webkit-keyframes checkboxAndRadioAnimation {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
  }
  50% {
    -webkit-transform: scale(1.5) rotate(45deg);
    transform: scale(1.5) rotate(45deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(45deg);
    transform: scale(1) rotate(45deg);
  }
}

@keyframes checkboxAndRadioAnimation {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
  }
  50% {
    -webkit-transform: scale(1.5) rotate(45deg);
    transform: scale(1.5) rotate(45deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(45deg);
    transform: scale(1) rotate(45deg);
  }
}

.input {
  background-color: #FFF;
  margin-right: 10px;
  padding: 0;
  position: relative;
  border-radius: 5px;
  margin-top: 5px;
  -webkit-transition: all .1s;
  transition: all .1s;
}

.input.inactive {
  border: 0px;
  pointer-events: none;
}

.input.curency input {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  color: rgba(47, 62, 104, 0.65);
}

.input :focus, .input :active {
  background-color: #FFF;
  border-radius: 3px;
}

.input input {
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #2F3E68;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2em;
  letter-spacing: 1.5px;
  margin: 0;
  outline: none;
  padding: 7px 16px;
  width: 100%;
  -webkit-transition: all .1s;
  transition: all .1s;
}

.input input::-webkit-input-placeholder, .input input::-ms-input-placeholder, .input input::-webkit-input-placeholder {
  color: #969da6;
}

.input input::-webkit-input-placeholder, .input input::-ms-input-placeholder, .input input::placeholder {
  color: #969da6;
}

.input-withIcon input {
  padding-right: 32px;
}

.input-icon {
  fill: #969da6;
  height: 16px;
  margin-top: -8px;
  position: absolute;
  right: 16px;
  top: 50%;
  width: 16px;
}

.input-fullWidth {
  display: block;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.inp-label {
  font-size: .75rem;
  color: rgba(47, 62, 104, 0.65);
  font-family: Roboto, sans-serif;
  font-weight: 400;
  margin-left: 12px;
  margin-bottom: -2px;
}

/**
 *    FORM COLLAPSED (items in row without gap between them)
 *
 *    Markup:
 *    -------
 *
 *    <div class="formCollapsed">
 *        <div class="input formCollapsed-item formCollapsed-itemPrimary">
 *            <input id="name" placeholder="Klingon search" type="text" />
 *        </div>
 *        <div class="select formCollapsed-item">
 *            <select name="country-code" id="country-code">
 *                <option value="AO"> Angola</option>
 *            </select>
 *        </div>
 *        <button class="formCollapsed-item button button-primary">
 *            Search
 *        </button>
 *    </div>
 * */
.formCollapsed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.formCollapsed-item {
  border-radius: 0 !important;
  margin: 0 !important;
}

.formCollapsed-item:first-child {
  border-bottom-left-radius: 3px !important;
  border-top-left-radius: 3px !important;
}

.formCollapsed-item:last-child {
  border-bottom-right-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.formCollapsed-item:not(:last-child) {
  border-right: 0;
}

.formCollapsed-itemPrimary {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

/**
 *    LOADING BAR
 *
 *    Markup:
 *    ---------
 *    <div class="loadingBar"></div>
 * */
.loadingBar {
  height: 6px;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.loadingBar::before {
  -webkit-animation: loading 2s linear infinite;
  animation: loading 2s linear infinite;
  background-color: #2F3E68;
  content: '';
  display: block;
  height: 6px;
  left: -300px;
  position: absolute;
  width: 300px;
}

@-webkit-keyframes loading {
  from {
    left: -300px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

@keyframes loading {
  from {
    left: -300px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

/**
 *    LOADING SPINNER
 *
 *    Markup:
 *    ---------
 *    <div class='loadingSpinner'>
 *        <span class='loadingSpinner-inner'></span>
 *        <span class='loadingSpinner-inner'></span>
 *        <span class='loadingSpinner-inner'></span>
 *        <span class='loadingSpinner-inner'></span>
 *    </div>
 * */
.loadingSpinner {
  -webkit-animation: rotateLoader 4s infinite;
  animation: rotateLoader 4s infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  display: block;
  height: 30px;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  position: fixed;
  top: 50%;
  width: 30px;
  z-index: 1000;
}

.loadingSpinner-inner {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  background-color: #2F3E68;
  border-radius: 100%;
  display: block;
  height: 9px;
  position: absolute;
  width: 9px;
}

.loadingSpinner-inner:nth-child(1) {
  -webkit-animation: translateBall1 1s infinite;
  animation: translateBall1 1s infinite;
  left: 0;
  top: 0;
  -webkit-transform: translate3d(4.5px, 4.5px, 0);
  transform: translate3d(4.5px, 4.5px, 0);
}

.loadingSpinner-inner:nth-child(2) {
  -webkit-animation: translateBall2 1s infinite;
  animation: translateBall2 1s infinite;
  right: 0;
  top: 0;
}

.loadingSpinner-inner:nth-child(3) {
  -webkit-animation: translateBall3 1s infinite;
  animation: translateBall3 1s infinite;
  bottom: 0;
  right: 0;
}

.loadingSpinner-inner:nth-child(4) {
  -webkit-animation: translateBall4 1s infinite;
  animation: translateBall4 1s infinite;
  bottom: 0;
  left: 0;
}

@-webkit-keyframes rotateLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotateLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes translateBall1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(4.5px, 4.5px, 0);
    transform: translate3d(4.5px, 4.5px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes translateBall1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(4.5px, 4.5px, 0);
    transform: translate3d(4.5px, 4.5px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes translateBall2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(-4.5px, 4.5px, 0);
    transform: translate3d(-4.5px, 4.5px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes translateBall2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(-4.5px, 4.5px, 0);
    transform: translate3d(-4.5px, 4.5px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes translateBall3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(-4.5px, -4.5px, 0);
    transform: translate3d(-4.5px, -4.5px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes translateBall3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(-4.5px, -4.5px, 0);
    transform: translate3d(-4.5px, -4.5px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes translateBall4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(4.5px, -4.5px, 0);
    transform: translate3d(4.5px, -4.5px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes translateBall4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(4.5px, -4.5px, 0);
    transform: translate3d(4.5px, -4.5px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/**
 *    NOTIFICATION
 *
 *    Markup:
 *    -------
 *
 *    <div class="notification notification--sucess">Success notification</div>
 *    <div class="notification notification--info">Success info</div>
 *    <div class="notification notification--error">Success error</div>
 *    <div class="notification notification--warning">Success warning</div>
 * */
.notification {
  border-radius: 5px;
  color: #2F3E68;
  background-color: #FFF;
  margin-bottom: 0px;
  padding: 10px 32px;
  border: 1px solid;
  width: auto;
  position: fixed;
  display: table;
  right: 40px;
  z-index: 99;
  top: 90vh;
}

.notification.inner {
  top: 0vh;
  right: -5vw;
}

.notification p:last-child {
  margin-bottom: 0;
}

.notification--primary {
  border-color: #2F3E68;
  -webkit-box-shadow: 0px 2px 15px rgba(47, 62, 104, 0.4);
          box-shadow: 0px 2px 15px rgba(47, 62, 104, 0.4);
  color: #2F3E68;
}

.notification--dark {
  background-color: #18232f;
  border-color: #2F3E68;
  -webkit-box-shadow: 0px 2px 15px rgba(47, 62, 104, 0.4);
          box-shadow: 0px 2px 15px rgba(47, 62, 104, 0.4);
  color: #FFF;
}

.notification--secondary {
  border-color: #e91e63;
  -webkit-box-shadow: 0px 2px 15px rgba(233, 30, 99, 0.4);
          box-shadow: 0px 2px 15px rgba(233, 30, 99, 0.4);
  color: #e91e63;
}

.notification--white {
  border-color: #000;
  -webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.4);
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.4);
}

.notification--success {
  border-color: #4caf50;
  -webkit-box-shadow: 0px 2px 15px rgba(76, 175, 80, 0.4);
          box-shadow: 0px 2px 15px rgba(76, 175, 80, 0.4);
  color: #4caf50;
}

.notification--info {
  border-color: #5bc0de;
  -webkit-box-shadow: 0px 2px 15px rgba(91, 192, 222, 0.4);
          box-shadow: 0px 2px 15px rgba(91, 192, 222, 0.4);
  color: #5bc0de;
}

.notification--warning {
  border-color: #f0ad4e;
  -webkit-box-shadow: 0px 2px 15px rgba(240, 173, 78, 0.4);
          box-shadow: 0px 2px 15px rgba(240, 173, 78, 0.4);
  color: #f0ad4e;
}

.notification--error {
  border-color: #e74c3c;
  -webkit-box-shadow: 0px 2px 15px rgba(231, 76, 60, 0.4);
          box-shadow: 0px 2px 15px rgba(231, 76, 60, 0.4);
  color: #e74c3c;
}

.notification--gray {
  background-color: #969da6;
}

.notification--gray-light {
  background-color: #eceff1;
}

/**
 *    PAGINATOR
 *
 *    Markup:
 *    -------
 *
 *    <ul class="paginator">
 *        <li class="paginator-item">
 *            <a href="#" class="paginator-itemLink">< Prev</a>
 *        </li>
 *        <li class="paginator-item">
 *            <a href="#" class="paginator-itemLink">1</a>
 *        </li>
 *        <li class="paginator-item">
 *            <a href="#" class="paginator-itemLink is-active">2</a>
 *        </li>
 *        <li class="paginator-item">
 *            <a href="#" class="paginator-itemLink">3</a>
 *        </li>
 *        <li class="paginator-item">
 *            <a href="#" class="paginator-itemLink">Next ></a>
 *        </li>
 *    </ul> */
.paginator-item {
  display: inline-block;
  margin-right: 4px;
}

.paginator-itemLink {
  background-color: #eceff1;
  border-radius: 3px;
  display: block;
  padding: 8px 16px;
}

.paginator-itemLink.is-active {
  background-color: #2F3E68;
  color: #fff;
  cursor: default;
}

/**
 *    TABLE
 *
 *    Markup:
 *    -------
 *
 *    <table class="table">
 *        <tr>
 *            <th>First column</th>
 *            <th>Second column</th>
 *            <th>Third column</th>
 *        </tr>
 *        <tr>
 *            <td="First column">Blue</td>
 *            <td="Second column">One</td>
 *            <td="Third column">My life fades</td>
 *        </tr>
 *    </table>
 * */
.table {
  background-color: #eceff1;
  border: 1px solid #d5d9db;
  border-collapse: collapse;
  color: #2F3E68;
  max-width: 100%;
  width: 100%;
}

.table th, .table td {
  border-bottom: 1px solid #d5d9db;
  padding: 8px;
  position: relative;
}

.table thead {
  border-bottom: 1px solid #d5d9db;
}

.table th {
  background-color: #fff;
  color: #969da6;
  font-size: 12px;
  font-weight: normal;
  padding: 8px;
  white-space: nowrap;
}

/**
 *    TABLE RESPONSIVE
 *
 *    Markup:
 *    -------
 *
 *    <table class="table table--responsive">
 *        <tr>
 *            <th>First column</th>
 *            <th>Second column</th>
 *            <th>Third column</th>
 *        </tr>
 *        <tr>
 *            <td data-th="First column">Blue</td>
 *            <td data-th="Second column">One</td>
 *            <td data-th="Third column">My life fades</td>
 *        </tr>
 *        <tr>
 *            <td data-th="First column">Green</td>
 *            <td data-th="Second column">Two</td>
 *            <td data-th="Third column">
 *                when the world was powered by the black fuel... and the desert
 *                sprouted great cities of pipe and steel.
 *            </td>
 *        </tr>
 *        <tr>
 *            <td data-th="First column">Yellow</td>
 *            <td data-th="Second column">Three</td>
 *            <td data-th="Third column">
 *                A whirlwind of looting, a firestorm of fear.
 *            </td>
 *        </tr>
 *    </table>
 * */
.table--responsive th {
  display: none;
}

.table--responsive td {
  display: block;
}

.table--responsive td::before {
  color: #969da6;
  content: attr(data-th) ": ";
  display: block;
  font-weight: normal;
}

.table--responsive td:first-child {
  border-top: 1px solid #d5d9db;
}

.table--responsive th, .table--responsive td {
  text-align: left;
}

@media (max-width: 767px) {
  .table--responsive td {
    border: 0;
  }
}

@media (min-width: 576px) {
  .table--responsive td::before {
    display: none;
  }
}

@media (min-width: 576px) {
  .table--responsive th, .table--responsive td {
    border-top: 1px solid #d5d9db;
    display: table-cell;
  }
}

/**
 *    TABS
 *
 *    Markup:
 *    -------
 *
 *    <div class="tabs">
 *        <a href="#" title="#" class="tabs-item">[...]</a>
 *        <a href="#" title="#" class="tabs-item is-selected">[...]</a>
 *    </div>
 * */
.tabs {
  border-bottom: 1px solid #d5d9db;
  text-align: center;
}

.tabs-item {
  border-bottom: 3px solid transparent;
  color: #969da6;
  display: inline-block;
  margin: 0 16px 0 0;
  min-width: 70px;
  padding: 16px;
  position: relative;
}

.tabs-item:hover {
  color: #2F3E68;
  text-decoration: none;
}

.tabs-item.is-selected {
  border-bottom: 3px solid #2F3E68;
  color: #2F3E68;
}

/**
 *    TAG
 *
 *    Markup:
 *    -------
 *
 *    <ul>
 *        <li class="tag">Fantasy</li>
 *        <li class="tag">Fiction</li>
 *        <li class="tag">Contemporary</li>
 *    </ul>
 *
 *    <ul>
 *        <li class="tag">
 *            <a href="#">Fantasy</a>
 *        </li>
 *        <li class="tag">
 *            <a href="#">Fiction</a>
 *        </li>
 *        <li class="tag">
 *             <a href="#">Contemporary</a>
 *        </li>
 *    </ul>
 * */
.tag {
  background-color: #eceff1;
  border-radius: 3px 0 0 3px;
  color: #2F3E68;
  display: inline-block;
  line-height: 16px;
  margin: 0 16px 16px 0;
  padding: 8px;
  position: relative;
}

.tag::before {
  border-bottom: 16px solid transparent;
  border-left: 8px solid #eceff1;
  border-top: 16px solid transparent;
  content: '';
  height: 0;
  position: absolute;
  right: -8px;
  top: 0;
  width: 0;
}

.tag::after {
  background: #fff;
  border-radius: 100%;
  content: '';
  height: 5px;
  margin-top: -2.5px;
  position: absolute;
  right: -2.5px;
  top: 50%;
  width: 5px;
}

.container, .container-fluid {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

/**
 * Demo website styles */
.v-spacing-small {
  position: relative;
  height: 60px;
  z-index: -1;
}

.anchor {
  display: block;
  position: relative;
  top: -65px;
  visibility: hidden;
}

.main-logo {
  width: 150px;
  fill: #2F3E68;
}

@media (max-width: 767px) {
  .main-logo {
    width: 100px;
  }
}

@media screen and (max-width: 550px) {
  .example-image {
    width: 50px;
  }
}

.examples {
  width: 100%;
}

.example1, .example2 {
  background-color: #eceff1;
  display: inline-block;
  max-width: 300px;
  padding: 16px;
  text-align: center;
}

@media screen and (max-width: 550px) {
  .example1, .example2 {
    max-width: 100%;
  }
  .example1 img, .example2 img {
    width: 100% !important;
  }
}

.language-js {
  font-size: 14px;
}

:not(pre) > code[class*="language-"] {
  background-color: #eceff1 !important;
}

pre {
  margin-bottom: 2em !important;
  overflow-x: auto;
}

pre[class*="language-"] {
  background-color: #eceff1 !important;
}

.example-code {
  max-width: 700px;
}

.example-code pre[class*="language-"] {
  font-size: 14px;
  margin: 0 !important;
}

.token.tag {
  background-color: transparent;
  border-radius: 0;
  color: #905;
  line-height: auto;
  margin: 0;
  padding: 0;
}

.token.tag:before, .token.tag:after {
  display: none;
}

.token.operator {
  background: none !important;
}

.header {
  background-color: #fff;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  left: 0;
  position: fixed;
  right: 0;
  top: -150%;
  -webkit-transition: top .5s ease-in-out;
  transition: top .5s ease-in-out;
  z-index: 2000;
}

.header::after {
  background: -webkit-gradient(linear, right top, left top, from(white), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 12px;
}

.header.is-visible {
  top: 0;
}

@media (min-width: 576px) {
  .header {
    padding: 16px 0;
  }
}

.header-inner {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.header-logo {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  position: relative;
}

.header-logo::after {
  background: -webkit-gradient(linear, left top, right top, from(white), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
  content: '';
  position: absolute;
  right: -12px;
  top: 0;
  width: 12px;
}

.header-logoImage {
  margin-right: 8px;
}

@media (min-width: 576px) {
  .header-logoImage {
    margin-right: 8px;
  }
}

.header-github {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.header-githubImage {
  margin-right: 12px;
}

@media (min-width: 576px) {
  .header-githubImage {
    margin-right: 0;
  }
}

.header-nav {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  margin-right: -12px;
  overflow-x: auto;
  padding: 20px 16px 20px 8px;
  white-space: nowrap;
}

@media (min-width: 576px) {
  .header-nav {
    margin-right: 0;
    padding: 0;
  }
}

.header-navItem {
  margin: 0;
  margin-right: 16px;
}

.header-navItem:last-child {
  margin-right: 0;
}

@media (max-width: 767px) {
  .hide-small {
    display: none;
  }
}
